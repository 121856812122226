<template>
  <div id="datenschutz">
    <div class="container col-6">
        <h1>Datenschutzerklärung</h1>

 <h5>1. Name und Kontaktdaten des Verantwortlichen</h5>
<p>Diese Datenschutzerklärung informiert über die Verarbeitung personenbezogener Daten auf der Kanzleiwebseite von Schwella + Partner Steuerberatungsgesellschaft</p>
Verantwortlicher:  Friedrich Schwella, <br>
Schwella + Partner Steuerberatungsgesellschaft, <br>
Inselstraße 24, <br>
Tel.: +49 355 355 480
<br>
<br>


<p>Kontaktdaten des Datenschutzbeauftragten:<br>
Der/die Datenschutzbeauftragte der Kanzlei ist unter der vorstehenden Kanzleianschrift und unter datenschutz(at)steuergestaltung.biz erreichbar. (bitte (at) durch @ ersetzen)</p>

<h5>2. Umfang und Zweck der Verarbeitung personenbezogener Daten</h5>
<p>
2.1 Aufruf der Webseite<br>
Beim Aufruf dieser Webseite www.steuergestaltung.biz werden durch den Internet-Browser, den der Besucher verwendet, automatisch Daten an den Server
dieser Webseite gesendet und zeitlich begrenzt in einer Protokolldatei (Logfile) gespeichert. Bis zur automatischen Löschung werden nachstehende Daten ohne weitere Eingabe des Besuchers gespeichert:<br>
-    IP-Adresse des Endgeräts des Besuchers,<br>
-    Datum und Uhrzeit des Zugriffs durch den Besucher,<br>
-    Name und URL der vom Besucher aufgerufenen Seite,<br>
-    Herkunftsland<br>
-    Webseite, von der aus der Besucher auf die Kanzleiwebseite gelangt (sog. Referrer-URL),<br>
-    Browser und Betriebssystem des Endgeräts des Besuchers sowie der Name des vom Besucher verwendeten Access-Providers.<br>
</p>

<p>Die Verarbeitung dieser personenbezogenen Daten ist gem. Art. 6 Abs. 1 Satz 1 Buchst. f) DSGVO gerechtfertigt. Die Kanzlei hat ein berechtigtes Interesse an der Datenverarbeitung zu dem Zweck,<br>
-    die Verbindung zur Webseite der Kanzlei zügig aufzubauen,<br>
-    eine nutzerfreundliche Anwendung der Webseite zu ermöglichen,<br>
-    die Sicherheit und Stabilität der Systeme zu erkennen und zu gewährleisten und<br>
-    die Administration der Webseite zu erleichtern und zu verbessern.<br>
Die Verarbeitung erfolgt ausdrücklich nicht zu dem Zweck, Erkenntnisse über die Person des Besuchers der Webseite zu gewinnen.</p>
<h6>2.2 Kontaktformular</h6>
<p>Besucher können über ein Online-Kontaktformular auf der Webseite Nachrichten an die Kanzlei übermitteln. Um eine Antwort empfangen zu können,
    ist zumindest die Angabe einer gültigen E-Mail-Adresse erforderlich. Alle weiteren Angaben kann die anfragende Person freiwillig geben.
    Mit Absenden der Nachricht über das Kontaktformular willigt der Besucher in die Verarbeitung der übermittelten personenbezogenen Daten ein.
    Die Datenverarbeitung erfolgt ausschließlich zu dem Zweck der Abwicklung und Beantwortung von Anfragen über das Kontaktformular.
    Dies geschieht auf Basis der freiwillig erteilten Einwilligung gem. Art. 6 Abs. 1 Satz 1 Buchst. a) DSGVO.
    Die für die Benutzung des Kontaktformulars erhobenen personenbezogenen Daten werden automatisch gelöscht,
    sobald die Anfrage erledigt ist und keine Gründe für eine weitere Aufbewahrung gegeben sind (z. B. anschließende Beauftra-gung unserer Kanzlei).</p>
<h6>2.3 Newsletter</h6>
<p>(keine Anmeldung zum Empfang über unsere Webseite möglich, ausschließlich bei Mandatierung direkt in unserer Kanzlei)</p>
<h5>3. Weitergabe von Daten</h5>
<p>Personenbezogene Daten werden an Dritte übermittelt, wenn<br>
-    nach Art. 6 Abs. 1 Satz 1 Buchst. a) DSGVO durch die betroffene Person ausdrücklich dazu eingewilligt wurde,<br>
-    die Weitergabe nach Art. 6 Abs. 1 Satz 1 Buchst. f) DSGVO zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen erforderlich ist und kein Grund zur An-nahme besteht,
dass die betroffene Person ein überwiegendes schutzwürdiges Interesse an der Nichtweitergabe ihrer Daten hat,<br>
-    für die Datenübermittlung nach Art. 6 Abs. 1 Satz 1 Buchst. c) DSGVO eine gesetzliche Verpflichtung besteht, und/oder<br>
-    dies nach Art. 6 Abs. 1 Satz 1 Buchst. b) DSGVO für die Erfüllung eines Vertragsverhältnisses mit der betroffenen Person erforderlich ist.<br>
In anderen Fällen werden personenbezogene Daten nicht an Dritte weitergegeben.</p>
<h5>4. Cookies</h5>
<p>Auf der Webseite können sog. Cookies eingesetzt werden. Das sind Datenpakete, die zwischen dem Server der Kanzleiwebseite und dem Browser des Besuchers ausgetauscht werden. Diese werden beim Besuch der Webseite von den jeweils verwendeten Geräten (PC, Notebook, Tablet, Smartphone etc.) gespeichert. Cookies können insoweit keine Schäden auf den ver-wendeten Geräten anrichten. Insbesondere enthalten sie keine Viren oder sonstige Schad-software. In den Cookies werden Informationen abgelegt, die sich jeweils im Zusammenhang mit dem spezifisch eingesetzten Endgerät ergeben. Die Kanzlei kann damit keinesfalls un-mittelbar Kenntnis von der Identität des Besuchers der Webseite erhalten.
Cookies werden nach den Grundeinstellungen der Browser größtenteils akzeptiert. Die Browsereinstellungen können so eingerichtet werden, dass Cookies entweder auf den verwendeten Geräten nicht akzeptiert werden, oder dass jeweils ein besonderer Hinweis erfolgt, bevor ein neuer Cookie angelegt wird. Es wird allerdings darauf hingewiesen, dass die Deaktivierung von Cookies dazu führen kann, dass nicht alle Funktionen der Webseite bestmöglich genutzt werden können.
Der Einsatz von Cookies dient dazu, die Nutzung des Webangebots der Kanzlei komfortabler zu gestalten. So kann beispielsweise anhand von Session-Cookies nachvollzogen werden, ob der Besucher einzelne Seiten der Webseite bereits besucht hat. Nach Verlassen der Webseite werden diese Session-Cookies automatisch gelöscht.
Zur Verbesserung der Benutzerfreundlichkeit werden temporäre Cookies eingesetzt. Sie werden für einen vorübergehenden Zeitraum auf dem Gerät des Besuchers gespeichert. Bei erneutem Besuch der Webseite wird automatisch erkannt, dass der Besucher die Seite bereits zu einem früheren Zeitpunkt aufgerufen hat und welche Eingaben und Einstellungen dabei vorgenommen wurden, um diese nicht wiederholen zu müssen.
Der Einsatz von Cookies erfolgt außerdem, um die Aufrufe der Webseite zu statistischen Zwecken und zum Zwecke der Verbesserung des Angebotes zu analysieren. Diese Cookies ermöglichen es, bei einem erneuten Besuch automatisch zu erkennen, dass die Webseite bereits zuvor vom Besucher aufgerufen wurde. Hier erfolgt nach einer jeweils festgelegten Zeit eine automatische Löschung der Cookies.
Die durch Cookies verarbeiteten Daten sind für die o. g. Zwecke zur Wahrung der berechtig-ten Interessen der Kanzlei nach Art. 6 Abs. 1 Satz 1 Buchst. f) DSGVO gerechtfertigt.</p>


<h5>5. Analyse-Dienste für Webseiten, Tracking</h5>

<p>Wir nutzen auf unserer Webseite keine Webseiten-Analysedienste.</p>

<h5>6. Plugins sozialer Netzwerke (Social Plugins)</h5>

<p> lehnen die Implementierung von Plugins sozialer Netzwerke ab.</p>

<h5>7. Ihre Rechte als betroffene Person</h5>
<p>Soweit Ihre personenbezogenen Daten anlässlich des Besuchs unserer Webseite verarbeitet werden, stehen Ihnen als „betroffene Person“ im Sinne der DSGVO folgende Rechte zu:</p>
<h6>7.1 Auskunft</h6>
<p>Sie können von uns Auskunft darüber verlangen, ob personenbezogene Daten von Ihnen bei uns verarbeitet werden. Kein Auskunftsrecht besteht,
    wenn die Erteilung der begehrten In-formationen gegen die Verschwiegenheitspflicht gem. § 57 Abs. 1 StBerG verstoßen würde oder die Informationen aus sonstigen Gründen,
    insbesondere wegen eines überwiegenden berechtigten Interesses eines Dritten, geheim gehalten werden müssen. Hiervon abweichend kann eine Pflicht zur Erteilung der Auskunft bestehen,
    wenn insbesondere unter Berücksich-tigung drohender Schäden Ihre Interessen gegenüber dem Geheimhaltungsinteresse über-wiegen. Das Auskunftsrecht ist ferner ausgeschlossen,
    wenn die Daten nur deshalb gespeichert sind, weil sie aufgrund gesetzlicher oder satzungsmäßiger Aufbewahrungsfristen nicht gelöscht werden dürfen oder ausschließlich
    Zwecken der Datensicherung oder der Datenschutzkontrolle dienen, sofern die Auskunftserteilung einen unverhältnismäßig hohen Auf-wand erfordern würde und die Verarbeitung
    zu anderen Zwecken durch geeignete technische und organisatorische Maßnahmen ausgeschlossen ist. Sofern in Ihrem Fall das Aus-kunftsrecht nicht ausgeschlossen ist und
    Ihre personenbezogenen Daten von uns verarbeitet werden, können Sie von uns Auskunft über folgende Informationen verlangen:<br>
-    Zwecke der Verarbeitung,<br>
-    Kategorien der von Ihnen verarbeiteten personenbezogenen Daten,<br>
-    Empfänger oder Kategorien von Empfängern, gegenüber denen Ihre personenbezoge-nen Daten offen gelegt werden, insbesondere bei Empfängern in Drittländern,<br>
-    falls möglich die geplante Dauer, für die Ihre personenbezogenen Daten gespeichert werden oder, falls dies nicht möglich ist, die Kriterien für die Festlegung der Speicher-dauer,<br>
-    das Bestehen eines Rechts auf Berichtigung oder Löschung oder Einschränkung der Verarbeitung der Sie betreffenden personenbezogenen Daten oder eines Widerspruchsrechts gegen diese Verarbeitung,<br>
-    das Bestehen eines Beschwerderechts bei einer Aufsichtsbehörde für den Datenschutz,<br>
-    sofern die personenbezogenen Daten nicht bei Ihnen als betroffene Person erhoben worden sind, die verfügbaren Informationen über die Datenherkunft,<br>
-    ggf. das Bestehen einer automatisierten Entscheidungsfindung einschließlich Profiling und aussagekräftige Informationen über die involvierte Logik sowie die Tragweite
und angestrebten Auswirkungen automatisierter Entscheidungsfindungen,<br>
-    ggf. im Fall der Übermittlung an Empfänger in Drittländern, sofern kein Beschluss der EU-Kommission über die Angemessenheit des Schutzniveaus nach Art. 45 Abs. 3 DSGVO vorliegt,<br>
-    Informationen darüber, welche geeigneten Garantien gem. Art. 46 Abs. 2 DSGVO zum Schutze der personenbezogenen Daten vorgesehen sind.</p>
<h6>7.2 Berichtigung und Vervollständigung</h6>
<p>Sofern Sie feststellen, dass uns unrichtige personenbezogene Daten von Ihnen vorliegen, können Sie von uns die unverzügliche
    Berichtigung dieser unrichtigen Daten verlangen. Bei unvollständigen Sie betreffenden personenbezogenen Daten können sie die Vervollständigung verlangen.</p>
<h6>7.3 Löschung</h6>
<p>Sie haben ein Recht auf Löschung („Recht auf Vergessenwerden“), sofern die Verarbeitung nicht zur Ausübung des Rechts auf freie Meinungsäußerung,
des Rechts auf Information oder zur Erfüllung einer rechtlichen Verpflichtung oder zur Wahrnehmung einer Aufgabe, die im öffentlichen Interesse liegt,
erforderlich ist und einer der nachstehenden Gründe zutrifft:<br>
-    Die personenbezogenen Daten sind für die Zwecke, für die sie verarbeitet wurden, nicht mehr notwendig.<br>
-    Die Rechtfertigungsgrundlage für die Verarbeitung war ausschließlich Ihre Einwilligung, welche Sie widerrufen haben.<br>
-    Sie haben Widerspruch gegen die Verarbeitung Ihrer personenbezogenen Daten eingelegt, die wir öffentlich gemacht haben.<br>
-    Sie haben Widerspruch gegen die Verarbeitung von uns nicht öffentlich gemachter per-sonenbezogener Daten eingelegt und es liegen keine vorrangigen berechtigten Gründe für die Verarbeitung vor.<br>
-    Ihre personenbezogenen Daten wurden unrechtmäßig verarbeitet.<br>
-    Die Löschung der personenbezogenen Daten ist zur Erfüllung einer gesetzlichen Verpflichtung, der wir unterliegen, erforderlich.<br><br>
Kein Anspruch auf Löschung besteht, wenn die Löschung im Falle rechtmäßiger nicht auto-matisierter Datenverarbeitung wegen der besonderen Art der Speicherung
nicht oder nur mit unverhältnismäßig hohem Aufwand möglich und Ihr Interesse an der Löschung gering ist. In diesem Fall tritt an die Stelle einer Löschung die Einschränkung der Verarbeitung.<br></p>
<h6>7.4 Einschränkung der Verarbeitung</h6>
<p>Sie können von uns die Einschränkung der Verarbeitung verlangen, wenn einer der nachstehenden Gründe zutrifft:<br>
-    Sie bestreiten die Richtigkeit der personenbezogenen Daten. Die Einschränkung kann in diesem Fall für die Dauer verlangt werden, die es uns ermöglicht, die Richtigkeit der Daten zu überprüfen.<br>
-    Die Verarbeitung ist unrechtmäßig und Sie verlangen statt Löschung die Einschränkung der Nutzung Ihrer personenbezogenen Daten.<br>
-    Ihre personenbezogenen Daten werden von uns nicht länger für die Zwecke der Verar-beitung benötigt, die Sie jedoch zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen benötigen.<br>
-    Sie haben Widerspruch gem. Art. 21 Abs. 1 DSGVO eingelegt. Die Einschränkung der Verarbeitung kann solange verlangt werden, wie noch nicht feststeht,<br>
ob unsere berech-tigten Gründe gegenüber Ihren Gründen überwiegen.<br><br>
Einschränkung der Verarbeitung bedeutet, dass die personenbezogenen Daten nur mit Ihrer Einwilligung oder zur Geltendmachung, Ausübung oder Verteidigung von
Rechtsansprüchen oder zum Schutz der Rechte einer anderen natürlichen oder juristischen Person oder aus Gründen eines wichtigen öffentlichen Interesses verarbeitet werden.
Bevor wir die Ein-schränkung aufheben, haben wir die Pflicht, Sie darüber zu unterrichten.</p>
<h6>7.5 Datenübertragbarkeit</h6>
<p>Sie haben ein Recht auf Datenübertragbarkeit, sofern die Verarbeitung auf Ihrer Einwilligung (Art. 6 Abs. 1 Satz 1 Buchst. a) oder Art. 9 Abs. 2 Buchst. a) DSGVO)
    oder auf einem Vertrag beruht, dessen Vertragspartei Sie sind und die Verarbeitung mithilfe automatisierter Verfahren erfolgt. Das Recht auf Datenübertragbarkeit
    beinhaltet in diesem Fall folgende Rechte, sofern hierdurch nicht die Rechte und Freiheiten anderer Personen beeinträchtigt werden: Sie können von uns verlangen,
    die personenbezogenen Daten, die Sie uns bereit gestellt haben, in einem strukturierten, gängigen und maschinenlesbaren Format zu erhalten. Sie haben das Recht,
    diese Daten einem anderen Verantwortlichen ohne Behinderung unserseits zu übermitteln. Soweit technisch machbar, können Sie von uns verlangen,
    dass wir Ihre personenbezogenen Daten direkt an einen anderen Verantwortlichen übermitteln.</p>

<h6>7.6 Widerspruch</h6>
<p>Sofern die Verarbeitung auf Art. 6 Abs. 1 Satz 1 Buchst. e) DSGVO (Wahrnehmung einer Aufgabe im öffentlichen Interesse oder in Ausübung öffentlicher Gewalt) oder auf Art. 6 Abs. 1 Satz 1 Buchst. f) DSGVO (berechtigtes Interesse des Verantwortlichen oder eines Dritten) beruht, haben Sie das Recht, aus Gründen, die sich aus Ihrer besonderen Situation ergeben, jederzeit gegen die Verarbeitung der Sie betreffenden personenbezogenen Daten Widerspruch einzulegen. Das gilt auch für ein auf Art. 6 Abs. 1 Satz 1 Buchst. e) oder Buchst. f) DSGVO gestütztes Profiling. Nach Ausübung des Widerspruchsrechts verarbeiten wir Ihre personenbezogenen Daten nicht mehr, es sei denn, wir können zwingende schutz-würdige Gründe für die Verarbeitung nachweisen, die Ihre Interessen, Rechte und Freiheiten überwiegen, oder die Verarbeitung dient der Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.
Sie können jederzeit Widerspruch gegen die Verarbeitung der Sie betreffenden personenbe-zogenen Daten zu Zwecken der Direktwerbung einlegen. Das gilt auch für ein Profiling, das mit einer solchen Direktwerbung in Verbindung steht. Nach Ausübung dieses Widerspruchs-rechts werden wir die betreffenden personenbezogenen Daten nicht mehr für Zwecke der Direktwerbung verwenden.
Sie haben die Möglichkeit, den Widerspruch telefonisch, per E-Mail, ggf. per Telefax oder an unsere zu Beginn dieser Datenschutzerklärung aufgeführte Postadresse unserer Kanzlei formlos mitzuteilen.</p>
<h6>7.7 Widerruf einer Einwilligung</h6>
<p>Sie haben das Recht, eine erteilte Einwilligung jederzeit mit Wirkung für die Zukunft zu widerrufen. Der Widerruf der Einwilligung kann telefonisch,
    per E-Mail, ggf. per Telefax oder an unsere Postadresse formlos mitgeteilt werden. Durch den Widerruf wird die Rechtmäßigkeit der Datenverarbeitung,
    die aufgrund der Einwilligung bis zum Eingang des Widerrufs erfolgt ist, nicht berührt. Nach Eingang des Widerrufs wird die Datenverarbeitung,
    die ausschließlich auf Ihrer Einwilligung beruhte, eingestellt.</p>
<h6>7.8 Beschwerde</h6>
<p>Wenn Sie der Ansicht sind, dass die Verarbeitung der Sie betreffenden personenbezogenen Daten rechtswidrig ist, können Sie Beschwerde bei einer Aufsichtsbehörde für den Daten-schutz einlegen,
    die für den Ort Ihres Aufenthaltes oder Arbeitsplatzes oder für den Ort des mutmaßlichen Verstoßes zuständig ist.
8. Stand und Aktualisierung dieser Datenschutzerklärung
Diese Datenschutzerklärung hat den Stand vom 25. Mai 2018. Wir behalten uns vor, die Da-tenschutzerklärung zu gegebener Zeit zu aktualisieren,
um den Datenschutz zu verbessern und/oder an geänderte Behördenpraxis oder Rechtsprechung anzupassen.</p>



    </div>
  </div>
</template>

<script>
import jsPDF from 'jspdf'

export default {
    name: 'impressum',
    data() {
        return {

        }
    },
    methods: {
        downloadPDF() {
            const pdf = '/src/assets/Fachberaterurkunde.pdf';
            window.open(pdf);
        }
    }
}
</script>

<style scoped>


#datenschutz {
    font-size: 800;
    color: #1f6892;
    text-align: left;
    display: block;
    position: static;
    padding-top: 10px;
    padding-bottom: 30px;
}

</style>